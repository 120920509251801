// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casino-js": () => import("./../../../src/pages/casino.js" /* webpackChunkName: "component---src-pages-casino-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-poker-js": () => import("./../../../src/pages/poker.js" /* webpackChunkName: "component---src-pages-poker-js" */),
  "component---src-pages-styles-notfound-styled-js": () => import("./../../../src/pages/styles/notfoundStyled.js" /* webpackChunkName: "component---src-pages-styles-notfound-styled-js" */),
  "component---src-pages-styles-poker-hero-styled-js": () => import("./../../../src/pages/styles/pokerHeroStyled.js" /* webpackChunkName: "component---src-pages-styles-poker-hero-styled-js" */),
  "component---src-pages-styles-product-styled-js": () => import("./../../../src/pages/styles/productStyled.js" /* webpackChunkName: "component---src-pages-styles-product-styled-js" */),
  "component---src-pages-styles-product-v-2-styled-js": () => import("./../../../src/pages/styles/productV2Styled.js" /* webpackChunkName: "component---src-pages-styles-product-v-2-styled-js" */),
  "component---src-pages-styles-title-main-styled-js": () => import("./../../../src/pages/styles/titleMainStyled.js" /* webpackChunkName: "component---src-pages-styles-title-main-styled-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-demo-games-js": () => import("./../../../src/templates/demo-games.js" /* webpackChunkName: "component---src-templates-demo-games-js" */)
}

